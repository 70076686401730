import { render, staticRenderFns } from "./MaterialsB2BPartnerOrBanker.vue?vue&type=template&id=6ef2bffc&scoped=true&"
import script from "./MaterialsB2BPartnerOrBanker.vue?vue&type=script&lang=js&"
export * from "./MaterialsB2BPartnerOrBanker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ef2bffc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ef2bffc')) {
      api.createRecord('6ef2bffc', component.options)
    } else {
      api.reload('6ef2bffc', component.options)
    }
    module.hot.accept("./MaterialsB2BPartnerOrBanker.vue?vue&type=template&id=6ef2bffc&scoped=true&", function () {
      api.rerender('6ef2bffc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/b2b/MaterialsB2BPartnerOrBanker.vue"
export default component.exports