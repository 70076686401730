import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c("h2", { staticClass: "table-title" }, [
        _vm._v(_vm._s(_vm.$t("materials"))),
      ]),
      _c(VSimpleTable, { staticClass: "table-content" }, [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("download")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("file")))]),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.files, function (file, i) {
            return _c("tr", { key: i }, [
              _c(
                "td",
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "px-2 ml-1 btnDownloadFile",
                      staticStyle: {
                        background: "transparent",
                        "max-width": "max-content",
                      },
                      attrs: {
                        icon: "",
                        download: file.name,
                        id: file.id,
                        name: file.name,
                        color: "primary",
                        "min-width": "0",
                        loading: _vm.fileDownloading === file.id,
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleDownloadFile(file)
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { small: "" } }, [
                        _vm._v("mdi-download"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("td", [_vm._v(_vm._s(file.name))]),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }