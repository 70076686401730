import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { fluid: "", tag: "section" } },
    [
      _c("div", [
        _c("h1", { staticClass: "insights-title" }, [
          _vm._v(_vm._s(_vm.$t("content"))),
        ]),
        _vm.carouselItems && _vm.carouselItems.length
          ? _c("h2", { staticClass: "insights-sub-title" }, [
              _vm._v(" " + _vm._s(_vm.$t("insights_dxa")) + " "),
            ])
          : _vm._e(),
        _vm.carouselItemsLoading
          ? _c(
              "div",
              { staticClass: "carousel-loading" },
              [
                _c(VProgressCircular, {
                  attrs: { indeterminate: "", size: "60", color: "primary" },
                }),
              ],
              1
            )
          : _c("div", { staticClass: "insights-container" }, [
              !_vm.carouselItemsLoading &&
              _vm.carouselItems &&
              _vm.carouselItems.length
                ? _c(
                    "div",
                    { staticClass: "carousel-container" },
                    [
                      _c(
                        VCarousel,
                        {
                          attrs: { cycle: "", height: "255px" },
                          model: {
                            value: _vm.model,
                            callback: function ($$v) {
                              _vm.model = $$v
                            },
                            expression: "model",
                          },
                        },
                        _vm._l(_vm.carouselItems, function (carouselItem, i) {
                          return _c(
                            VCarouselItem,
                            {
                              key: i,
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleOpenVidoDialog(carouselItem)
                                },
                              },
                            },
                            [
                              _c(VSheet, { attrs: { height: "100%" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "center",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("img", {
                                        staticStyle: { "max-width": "100%" },
                                        attrs: {
                                          src: _vm.gs.get_photo_path(
                                            carouselItem.urlThumb
                                          ),
                                          alt: carouselItem.thumbFileName,
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.language === _vm.LanguageEnum.pt
                ? _c("div", [
                    !_vm.carouselItems.length
                      ? _c("h2", { staticClass: "insights-sub-title" }, [
                          _vm._v(" " + _vm._s(_vm.$t("insights_dxa")) + " "),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "blog-container" }, [
                      _c("div", { staticClass: "blog-content" }, [
                        _c("h2", { staticClass: "blog-title" }, [
                          _vm._v(_vm._s(_vm.$t("blog_dxa_title"))),
                        ]),
                        _c("p", { staticClass: "blog-sub-title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("blog_dxa_sub_title")) + " "
                          ),
                        ]),
                        _c(
                          "a",
                          {
                            staticClass: "blog-link",
                            attrs: {
                              href: "https://www.dxainvest.com/blog",
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("read_blog")))]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
      ]),
      _vm.filesLoading
        ? _c(
            "div",
            { staticClass: "files-loading" },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", size: "60", color: "primary" },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              !_vm.filesLoading && _vm.materialsItems.length
                ? _c("MaterialsTable", { attrs: { files: _vm.materialsItems } })
                : _vm._e(),
            ],
            1
          ),
      _vm.videoDialog
        ? _c(
            VDialog,
            {
              attrs: { scrollable: "" },
              model: {
                value: _vm.videoDialog,
                callback: function ($$v) {
                  _vm.videoDialog = $$v
                },
                expression: "videoDialog",
              },
            },
            [_c("VideoDialog", { attrs: { video: _vm.videoToOpen } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }